import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "./header";
import { headingClass } from "./heading";

const Price = ({ price, option }: { price: string; option?: string }) => (
  <div className="flex flex-col items-end mb-4">
    <div
      className="px-1"
      css={css`
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.6),
          0 0 4px rgba(255, 255, 255, 0.8);
      `}
    >
      <span className="text-green-500 text-4xl">&euro;</span>
      <span className="text-4xl text-green-600">{price.split(",")[0]}</span>
      <span className="text-green-500 text-2xl">
        {price.indexOf(",") > 0 ? "," + price.split(",")[1] : ""}
      </span>
    </div>
    {option && (
      <h5 className="inline-block uppercase text-white text-right whitespace-nowrap ml-auto">
        <span className="bg-red-500 px-1 ">
          <Trans>{option}</Trans>
        </span>
      </h5>
    )}
  </div>
);

const Food = ({
  name,
  price,
  prices,
  recipe,
  special,
  image,
  pairing,
}: {
  name: string;
  price?: string;
  prices?: { option: string; price: string }[];
  recipe: string;
  special?: { description: string };
  image: string;
  pairing?: string;
}) => {
  return (
    <li className="bg-white overflow-hidden">
      <img
        src={image}
        className="aspect-square block lg:hover:scale-110 transition-transform w-full"
        title={name}
      />
      <div className="-mt-20 lg:min-h-[7rem] bg-gradient-to-t from-white to-white/0 backdrop-blur-sm">
        <div className="flex gap-4 justify-between">
          <div className="p-4">
            <h4 className="text-2xl text-red-500 uppercase">
              <span className="bg-white/80">
                <Trans>{name}</Trans>
              </span>
            </h4>
            <p>
              <span className="bg-white/60">
                <Trans>{recipe}</Trans>
              </span>
            </p>
            {special && (
              <p className="text-sm mt-4 text-gray-900">
                <Trans>{special.description}</Trans>
              </p>
            )}
            {pairing && (
              <p className="text-sm text-red-900">
                <Trans>{pairing}</Trans>
              </p>
            )}
          </div>
          <div className="p-4 bg-white/40 lg:bg-white/80 flex flex-col items-end">
            {price && <Price price={price} />}
            {prices && prices.map(Price)}
          </div>
        </div>
      </div>
    </li>
  );
};

const AllMenu = () => {
  const {
    allMenuJson: { nodes },
  } = useStaticQuery(query);
  return <ul>{nodes.map(Menu)}</ul>;
};

const Menu = ({
  title,
  description,
  items,
}: {
  title: string;
  description: string;
  items: any[];
}) => (
  <li className="snap-start min-h-screen even:bg-yellow-300">
    <HeaderSpacer />
    <div className="p-4">
      <h3 className={headingClass}>
        <Trans>{title}</Trans>
      </h3>
      <p className="text-xl whitespace-pre-line">
        <Trans>{description}</Trans>
      </p>
    </div>
    <ol className="grid grid-cols-1 lg:grid-cols-3">{items.filter( value => value.enabled).map(Food)}</ol>
  </li>
);

export default AllMenu;

const query = graphql`
  query MenuQuery {
    allMenuJson {
      nodes {
        items {
          enabled
          image
          name
          price
          prices {
            option
            price
          }
          recipe
          special {
            description
          }
          pairing
          allergens
        }
        title
        description
      }
    }
  }
`;
