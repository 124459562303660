import { MotionConfig, m, useInView, Variant, Variants } from "framer-motion";
import React from "react";
import { Trans } from "react-i18next";
import skyline from "../images/background/skyline.svg";
import biker from "../images/background/biker.png";
import tire from "../images/background/tire.png";

const container: Variants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.3,
    },
  },
};

const item: Variants = {
  hidden: { y: 40, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const Arrow = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
} & React.ComponentProps<"li">) => (
  <m.li
    variants={item}
    className={`flex px-4 my-2 items-baseline ${className}`}
  >
    <span className="text-white text-4xl font-bold mr-2 tracking-tighter">
      {">"}
    </span>
    <div>{children}</div>
  </m.li>
);

export const PromoWebshop = ({
  className = "",
  tight = false,
}: {
  tight?: boolean;
  className?: string;
}) => {
  const selfRef = React.useRef<HTMLDivElement>(undefined!);
  const isInView = useInView(selfRef);

  return (
    <div
      ref={selfRef}
      style={{
        height: tight ? "calc(100vh - 12rem)" : "calc(100vh - 4rem)",
        paddingTop: tight ? "0" : "8rem",
      }}
      className={`snap-start h-[calc(100vh_-_12rem)] relative overflow-hidden bg-yellow-400 text-brown-500 tracking-tight ${className}`}
    >
      <div className="flex flex-col">
        <m.ul variants={container} animate={isInView ? "visible" : "hidden"}>
          <Arrow>
            <h3 className="text-4xl uppercase font-black bg-yellow-500/50 z-[11] relative">
              <Trans>Visit our new webshop</Trans>
            </h3>
          </Arrow>
          <Arrow>
            <p className="text-2xl uppercase bg-yellow-500/50 z-[11] relative">
              {/* prettier-ignore */}
              <Trans>Create an account and take advantage of the many benefits</Trans>
            </p>
            <div className="whitespace-pre-line z-[11] relative">
              <div className="uppercase mt-4 inline-block border-8 border-white ring-inset px-3 font-semibold text-5xl">
                10% <Trans>Off</Trans>
              </div>
              {"\n"}
              <span className="uppercase text-2xl bg-yellow-500/50 ml-4">
                <Trans>on your 1st order</Trans>
              </span>
            </div>
          </Arrow>
          <Arrow>
            <p className="text-2xl uppercase ml-4 z-[11] relative">
              <span className="bg-yellow-500/50">
                {/* prettier-ignore */}
                <Trans>Insert Promo Code: <strong className="select-all font-normal">10%PANZERO1ST</strong></Trans>
              </span>
            </p>
          </Arrow>
        </m.ul>
        <ul className="relative w-1/2 z-[11]">
          <Arrow className="absolute top-0 text-lg animate-[slideInOut_6s_linear_infinite_both]">
            <span className="bg-yellow-500/40">
              {/* prettier-ignore */}
              <Trans>Save and manage your delivery and billing addresses</Trans>
            </span>
          </Arrow>
          <Arrow className="absolute top-0 text-lg animate-[slideInOut_6s_linear_infinite_both_2s]">
            <span className="bg-yellow-500/40">
              {/* prettier-ignore */}
              <Trans>No more annoying data entry when ordering</Trans>
            </span>
          </Arrow>
          <Arrow className="absolute top-0 text-lg animate-[slideInOut_6s_linear_infinite_both_4s]">
            <span className="bg-yellow-500/40">
              {/* prettier-ignore */}
              <Trans>Sign up for our newsletter to keep up to date with the latest offers and coupons</Trans>
            </span>
          </Arrow>
        </ul>
      </div>
      <div>
        <div className="absolute bottom-6 z-10  animate-cycle text-[.8vh] lg:text-[0.5rem]">
          <img className="w-[50em] z-10 relative" src={biker} />
          <img
            className="block w-[16em] animate-spin absolute bottom-[0.5em] left-[9em] z-[8]"
            src={tire}
          />
          <img
            className="block w-[16em] animate-spin absolute bottom-[0.5em] right-[0.5em] z-[8]"
            src={tire}
          />
        </div>
        <div
          className="absolute bottom-12 z-0 block w-screen h-44 animate-pass"
          style={{
            background: `top center / 800px auto url(${skyline}) repeat-x`,
          }}
        ></div>
      </div>
    </div>
  );
};
