import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import panzerottoMenu from "../images/background/panzero-menu.jpg";
import panzerottoProofing from "../images/background/panzero-proofing.jpg";
import panzerottoCooking from "../images/background/panzerotto-cooking.jpg";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import styled from "@emotion/styled";
import AllMenu from "../components/all_menu";
import { headingClass } from "../components/heading";
import { PromoWebshop } from "../components/promo";
import SEO from "../seo";

const MenuCover = styled.div`
  background: center center / cover url(${panzerottoMenu});
`;
const MenuCover2 = styled.div`
  background: center center / cover url(${panzerottoProofing});
`;
const MenuCover3 = styled.div`
  background: center center / cover url(${panzerottoCooking});
`;

const Menu = () => {
  return (
    <Layout className="snap-proximity">
      <div className="min-h-screen snap-end">
        <HeaderSpacer />
        <div className="bg-yellow-300 grid grid-cols-4 grid-rows-2 h-[calc(100vh_-_11rem)]">
          <MenuCover3 className="col-start-4 col-end-5 row-span-1 lg:col-start-3" />
          <MenuCover2 className="col-start-1 col-span-2 row-span-1 row-start-2" />
          <MenuCover className="col-start-3 col-end-5 row-span-1 row-start-2" />
          <div className="col-span-3 row-start-1 flex flex-col p-4 gap-8 lg:col-span-2">
            <h3 className={headingClass}>
              <Trans>Our Menu</Trans>
            </h3>
            <p className="text-xl">
              {/* prettier-ignore */}
              <Trans>Panzerò is the place for all the best Apulian cuisine! Traditional dishes from Puglia Italy, cooked with passion and the finest ingredients.</Trans>
            </p>
          </div>
        </div>
      </div>
      <AllMenu />
      <PromoWebshop />
    </Layout>
  );
};

export default Menu;

export const Head: HeadFC = () => <SEO title="Our Menu" />;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
